import React from 'react'
import Helmet from 'react-helmet'
import { Link, graphql } from 'gatsby'
import Layout from '../layouts'
import CategoriesPage from './categories-page'

class CategoryRoute extends React.Component {

  render() {
    console.log(this.props.pageContext,'this.props.pageContext')
    return ( 
      <CategoriesPage name={this.props.pageContext.tag} next={this.props.pageContext.nextCat} prev={this.props.pageContext.prevCat} data={this.props.data} location={this.props.location}/>
    )
  }
}

export default CategoryRoute

export const tagPageQuery = graphql`
  query CategoryPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { categories: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            description
            featuredimage {
              publicURL
              childImageSharp{
                      fluid{
                        srcSet
                        src
                      }
                    }
          }
          image_1 {
            publicURL
            childImageSharp{
                    fluid{
                      srcSet
                      src
                    }
                  }
        }
          image_2 {
            publicURL
            childImageSharp{
                    fluid{
                      srcSet
                      src
                    }
                  }
        }
          image_3 {
            publicURL
            childImageSharp{
                    fluid{
                      srcSet
                      src
                    }
                  }
        }
          image_4 {
            publicURL
            childImageSharp{
                    fluid{
                      srcSet
                      src
                    }
                  }
        }
          image_5 {
            publicURL
            childImageSharp{
                    fluid{
                      srcSet
                      src
                    }
                  }
        }
          image_6 {
            publicURL
            childImageSharp{
                    fluid{
                      srcSet
                      src
                    }
                  }
        }
          image_7 {
            publicURL
            childImageSharp{
                    fluid{
                      srcSet
                      src
                    }
                  }
        }
            image_8 {
                publicURL
                childImageSharp{
                        fluid{
                          srcSet
                          src
                        }
                      }
            }
          }
        }
      }
    }
  }
`
